<style lang="scss" scoped>
</style>
<template>
  <div class="">
    <el-form label-width="200px">
      <el-form-item
        label="登錄頁Banner："
        prop="banner"
        class="wrap-form-item-130"
      >
        <el-image
          class="goodImg"
          :src="state.infoData.banner"
          :preview-src-list="[state.infoData.banner]"
          style="width:200px;height:100px;"
        ></el-image>
      </el-form-item>
      <el-form-item label="商鋪名：" prop="shop_name">
        <span>{{ state.infoData.shop_name || "--" }}</span>
      </el-form-item>
      <el-form-item label="聯繫郵箱：" prop="email">
        <span>{{ state.infoData.email || "--" }}</span>
      </el-form-item>
      <el-form-item label="聯繫電話：" prop="phone">
        <span>{{ state.infoData.phone || "--" }}</span>
      </el-form-item>
      <el-form-item label="商鋪地址：" prop="address">
        <span>{{ state.infoData.address || "--" }}</span>
      </el-form-item>
      <el-form-item label="最低庫存提醒：" prop="min_stock">
        <span>{{ state.infoData.min_stock || "--" }}</span>
      </el-form-item>
      <el-form-item label="營業時間：" prop="time">
        <span
          >早上：{{
            state.infoData.start_business || "--"
          }}&emsp;至&emsp;晚上：{{ state.infoData.end_business || "--" }}</span
        >
      </el-form-item>
      <el-form-item label="商鋪介紹：" prop="details" class="editor">
        <span v-html="state.infoData.details"></span>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" style="margin-left: 200px">
      <el-button type="primary" @click="editInit(state.infoData)"
        >編 輯</el-button
      >
    </div>
    <!-- 編輯 -->
    <pop-up ref="edit" @onSuccess="init"></pop-up>
  </div>
</template>

<script>
import { defineComponent, reactive, getCurrentInstance, ref } from "vue";
import PopUp from "./components/pop-up";

export default defineComponent({
  components: {
    PopUp,
  },

  setup() {
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http } = proxy;
    const edit = ref();
    const state = reactive({
      infoData: {},
    });

    const init = async () => {
      const res = await $http.getShopsData();
      state.infoData = res.data;
    };

    init();

    const editInit = (item) => {
      edit.value.init(item);
    };

    return { state, editInit, init, edit };
  },
});
</script>
